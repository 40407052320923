<template>
    <div v-if="rating > 0" class="rating  tgcss-flex tgcss-items-center">
        <!-- ستاره‌های پر -->
        <svg v-for="n in fullStars"  :key="'full-' + n" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <linearGradient id="grad__star__101">
                    <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                </linearGradient>
            </g>
            <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__101)"></path>
        </svg>
        <!-- ستاره نیمه‌پر -->
        <svg v-if="hasHalfStar" style="transform: scaleX(-1);" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <linearGradient id="grad__star__64">
                    <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="50%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="50%" stop-color="#fefefe" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                </linearGradient>
            </g>
            <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__64)"></path>
        </svg>
        <!-- ستاره‌های خالی -->
        <svg v-for="n in emptyStars" :key="'empty-' + n" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <linearGradient>
                    <stop offset="0%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="#f8931b" stop-opacity="1"></stop>
                    <stop offset="100%" stop-color="#fefefe" stop-opacity="1"></stop>
                </linearGradient>
            </g>
            <path d="M7.25851 10.7125L7.00027 10.5563L6.74174 10.712L3.62924 12.587L3.62835 12.5876C3.43879 12.7023 3.20663 12.5349 3.2567 12.3188C3.25672 12.3187 3.25674 12.3186 3.25675 12.3186L4.0817 8.7788L4.15017 8.48499L3.92218 8.28743L1.16968 5.90243L1.1695 5.90228C1.00027 5.75578 1.09449 5.48333 1.3094 5.46624L1.3094 5.46625L1.31204 5.46602L4.93454 5.15852L5.2347 5.13304L5.35246 4.85577L6.76996 1.51827L6.76997 1.51828L6.77078 1.51633C6.85425 1.31747 7.14525 1.31747 7.22871 1.51633L7.22938 1.5179L8.64688 4.8629L8.76452 5.14052L9.06496 5.16602L12.6875 5.47352L12.6875 5.47353L12.6901 5.47374C12.905 5.49083 12.9992 5.76328 12.83 5.90978L12.8298 5.90993L10.0773 8.29493L9.84933 8.49249L9.9178 8.7863L10.7427 12.3261C10.793 12.5423 10.5608 12.7098 10.3712 12.5951L10.371 12.595L7.25851 10.7125Z" stroke="#f8931b" fill="url(#grad__star__100)"></path>
        </svg>
    </div>
</template>
    
<script>
    export default {
        name: 'stars',
        props: ['star', 'size'],
        components: {},
        data() {
            return {
                rating: this.star || 0
            }
        },
        mounted() {
        },
        computed: {
            
            fullStars() {
                return Math.floor(this.rating);
            },
            hasHalfStar() {
                return this.rating % 1 >= 0.5;
            },
            emptyStars() {
                return 5 - this.fullStars - (this.hasHalfStar ? 1 : 0);
            }
        },
        methods: {
        },
    }
</script>  